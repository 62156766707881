import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`De nouvelles questions... et les réponses`}</h3>
    <p>{`Le précédent `}<a parentName="p" {...{
        "href": "/blog/bigdata"
      }}>{`billet`}</a>{` décrit l'analyse des données journalières et horaires d'envois de SMS. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "467px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47.239263803680984%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABgUlEQVQoz4WSa28SQRhG+cfWH+LH1mj1e1u/mKhR05jSmNgGxILdCwxYliJU6F65LLuzuxxnd5VE2sZJTp53nklOJpmp6LpO2zC46vXomCY/RAfRaaO1mny/aBIEPg+t9T1dJUkS5mHMbRDiziPs2QpbzfYsLNJdxPhL+Q9O3oWS9Xp9h0purVs+B7UxLz9fs3ti8bQ6YK9qKVSq/e5Jv+j/5rNTi0/GdHPPbFvYnS447ztUxS3v9QkfjId5q/3CGAeEUYy3WBHFUjm3hJNFSs/L6Psp2lRy+R8MO0F4KaaT4CXlPbM0UcKsFM4mP5kaX7kxm4y1OuPLmqJ+DzVGCl80yIYt0usWctAkk/HmkQrhSv/I/PgJy9M9gleP8Q938I92CI7K3MyHZXoq3YOcRwUr8xg5+kbiDUthenOBFO+Q5muixr7ixYZ4i7vdPnH9ObL9hswWpXDpDHEGGt5I4F41cLtnuL3zAqdbks+2+KLO8v3ZH9Scd1ZLfSP1/ZYRvwG3VYtw3lQppgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "sms1",
          "title": "sms1",
          "src": "/static/04513d0837043df76a9840c281adfd6d/85ff8/sms1.png",
          "srcSet": ["/static/04513d0837043df76a9840c281adfd6d/222b7/sms1.png 163w", "/static/04513d0837043df76a9840c281adfd6d/ff46a/sms1.png 325w", "/static/04513d0837043df76a9840c281adfd6d/85ff8/sms1.png 467w"],
          "sizes": "(max-width: 467px) 100vw, 467px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Malgré le fait que toutes les données sont sur la table, certaines questions utiles n'ont pas de réponses directes. La première tout à fait dans le prolongement des données présentées: « Quel est le volume global par mois ? Augmente t-il ? Baisse t-il ? »`}</p>
    <p>{`Cette question tout à fait valide peut être répondue élégamment en enrichissant l'indicateur visuel pour superposer la journée et le mois qui devient une zone ombrée en arrière plan. `}</p>
 <iframe style={{
      "border": "0px"
    }} src="/sandbox/sms2.html" scrolling="no" width="100%" height="500px">
    </iframe>
    <p>{`Au passage, la codification (bleu=semaine, gris=week-end) se transforme en (bleu=yaécoledemain, gris=grassematpossible) bien plus efficace pour l'analyse et ... la décision.`}</p>
    <h3>{`De nouvelles représentations interactives`}</h3>
    <p>{`Mais d'autres questions restent inaccessibles à ce mode de visualisation. Par exemple:`}</p>
    <ul>
      <li parentName="ul">{`Les envois de SMS après 23 heures les jours de semaine ont ils baissés depuis la rentrée scolaire ?`}</li>
      <li parentName="ul">{`Y a t il beaucoup d'émissions de SMS durant les heures de cours ?`}</li>
      <li parentName="ul">{`Quel est le jour de la semaine d'émission du plus grand nombre de SMS ?`}</li>
      <li parentName="ul">{`...`}</li>
    </ul>
    <p>{`D'où l'idée d'un tableau de bord plus dédié qui utilise html5 (Visible qu'avec un navigateur récent: firefox, chrome, safari, internet explorer`}{`>`}{`=9, .... ). En jouant sur la sélection des données sur les trois panneaux, il est possible de répondre aux trois questions précédentes. Saurez vous le faire ?`}</p>
 <iframe style={{
      "border": "0px"
    }} src="/sandbox/dc/dc.html" scrolling="no" width="100%" height="800">
    </iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      